<template>
  <div class="dashboard-inner">
    <div class="dashboard-inner__header">
      <div class="dashboard-router">
        <router-link
          class="dashboard-router__item mr-4 mr-md-6"
          exact-path
          active-class="dashboard-router--active"
          :to="{ name: routes.DASHBOARD_PAYMENTS }"
        >
          {{ $t('dashboard.payments') }}
        </router-link>
        <router-link
          class="dashboard-router__item mr-4 mr-md-6"
          exact-path
          active-class="dashboard-router--active"
          :to="{ name: routes.DASHBOARD_TASKS }"
        >
          {{ $t('dashboard.tasks') }}
        </router-link>
      </div>

      <dashboard-menu
        v-if="media.isMobile && ((isTasksMode && !isTasksDataMissing) || (isPaymentsMode && !isPaymentsDataMissing))"
        :mode="mode"
        @open-filter="openFilter"
        @open-period="openPeriod"
        @open-settings="openSettings"
        @export-payments="exportPayments"
      />
      <div
        v-else-if="(isTasksMode && !isTasksDataMissing) || (isPaymentsMode && !isPaymentsDataMissing)"
        class="d-flex"
      >
        <v-btn
          v-show="!isTasksMode"
          color="secondary"
          large
          class="primary--text"
          elevation="0"
          @click="exportPayments"
        >
          {{ $t('button.export') }}
          <v-icon right>mdi-file-download</v-icon>
        </v-btn>
        <icon-button color="secondary" class="primary--text ml-2" @click="openSettings">
          <v-icon>mdi-cog</v-icon>
        </icon-button>
      </div>
    </div>

    <div v-if="isTasksMode">
      <dashboard-missing-data v-if="isTasksDataMissing" :mode="mode" />
      <dashboard-tasks
        v-else
        :mode="mode"
        :is-open-settings="isOpenSettings"
        :is-open-filters="isOpenFilters"
        :is-open-period="isOpenPeriod"
        :dashboard-filters-value="dashboardFiltersValueTasks"
        @close-settings="isOpenSettings = false"
        @close-period="isOpenPeriod = false"
        @close-filters="isOpenFilters = false"
        @set-filters="newVal => (dashboardFiltersValueTasks = newVal)"
        @missing-data="isTasksDataMissing = true"
      />
    </div>

    <div v-if="isPaymentsMode">
      <dashboard-missing-data v-if="isPaymentsDataMissing" :mode="mode" />
      <dashboard-payments
        v-else
        :mode="mode"
        :is-open-settings="isOpenSettings"
        :is-open-filters="isOpenFilters"
        :is-open-period="isOpenPeriod"
        :dashboard-filters-value="dashboardFiltersValuePayments"
        @close-settings="isOpenSettings = false"
        @close-period="isOpenPeriod = false"
        @close-filters="isOpenFilters = false"
        @set-filters="newVal => (dashboardFiltersValuePayments = newVal)"
        @missing-data="isPaymentsDataMissing = true"
      />
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import DashboardTasks from '@/components/Dashboard/DashboardTasks.vue';
import DashboardPayments from '@/components/Dashboard/DashboardPayments.vue';
import DashboardMenu from '@/components/Dashboard/Menu.vue';
import DashboardMissingData from '@/components/Dashboard/MissingData.vue';

import dashboardService from '@/services/dashboard';
import { debounce } from '@/utils/delay';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';

import { PAYMENTS, TASKS } from '@/constants/dashboardModes';
import * as routes from '@/constants/routes';

export default {
  name: 'DashboardGraph',

  components: {
    IconButton,
    DashboardTasks,
    DashboardPayments,
    DashboardMenu,
    DashboardMissingData,
  },

  props: {
    mode: { type: String, required: true },
  },

  data() {
    return {
      routes,
      isOpenFilters: false,
      isOpenPeriod: false,
      isOpenSettings: false,
      isPaymentsDataMissing: false,
      isTasksDataMissing: false,
      dashboardFiltersValuePayments: {
        unitsMeasurement: { value: 'eur' },
        accounting: { value: 0 },
        datePeriod: [],
        projects: { all: false, exclude: [], include: [] },
        servicesTypes: { all: false, exclude: [], include: [] },
        contractors: { all: false, exclude: [], include: [] },
        clients: { all: false, exclude: [], include: [] },
        paymentTypes: { all: false, exclude: [], include: [] },
      },
      dashboardFiltersValueTasks: {
        datePeriod: [],
        projects: { all: false, exclude: [], include: [] },
        servicesTypes: { all: false, exclude: [], include: [] },
        clients: { all: false, exclude: [], include: [] },
        performers: { all: false, exclude: [], include: [] },
      },
    };
  },

  inject: ['media'],

  computed: {
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS;
    },
  },

  methods: {
    // eslint-disable-next-line func-names
    debouncedSetFilter: debounce(function({
      datePeriod,
      unitsMeasurement,
      servicesTypes,
      projects,
      clients,
      contractors,
    }) {
      this.setFilter({ datePeriod, unitsMeasurement, servicesTypes, projects, clients, contractors });
    },
    500),
    exportPayments() {
      const config = {
        invoiceDateFrom: this.dashboardFiltersValuePayments.datePeriod[0],
        invoiceDateTo: this.dashboardFiltersValuePayments.datePeriod[1],
        unitsMeasurement: this.dashboardFiltersValuePayments?.unitsMeasurement.value,
        ...this.normalizeFilters(this.dashboardFiltersValuePayments),
      };
      dashboardService.downloadCompanyGraphicData('payments.xlsx', config);
    },
    normalizeFilters({ servicesTypes, projects, clients, contractors, paymentTypes }) {
      return {
        serviceTypes: checkEmptyParams(servicesTypes) ? undefined : extractParamsFromMultiSelectObject(servicesTypes),
        projects: checkEmptyParams(projects) ? undefined : extractParamsFromMultiSelectObject(projects),
        clients: checkEmptyParams(clients) ? undefined : extractParamsFromMultiSelectObject(clients),
        contractors: checkEmptyParams(contractors) ? undefined : extractParamsFromMultiSelectObject(contractors),
        paymentTypes: checkEmptyParams(paymentTypes) ? undefined : extractParamsFromMultiSelectObject(paymentTypes),
      };
    },
    openSettings() {
      this.isOpenSettings = true;
    },
    openFilter() {
      this.isOpenFilters = true;
    },
    openPeriod() {
      this.isOpenPeriod = true;
    },
  },
  cancelSource: null,
};
</script>

<style lang="scss">
.dashboard-inner {
  margin-top: 40px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.dashboard-router {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;

  &__item {
    color: rgba($--black-color-0, 0.5) !important;
    text-decoration: none !important;
    font-weight: $--font-weight-bold;
    font-size: 24px;
    line-height: 32px;
  }

  &--active {
    color: $--black-color-0 !important;
    text-decoration: underline !important;
  }
}
</style>
