<template>
  <div>
    <div v-if="isLoading" class="dashboard-extended-loader">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <iframe
      v-if="metabaseUrl"
      :src="metabaseUrl"
      class="dasboard-iframe"
      frameborder="0"
      allowtransparency
      @load="isLoading = false"
    ></iframe>
  </div>
</template>

<script>
import dashboardService from '@/services/dashboard';
import { fromUnixTime, differenceInMilliseconds } from 'date-fns';

export default {
  name: 'ExtendedItem',

  props: {
    dashboardId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      metabaseUrl: null,
      timeoutId: null,
    };
  },

  mounted() {
    this.isLoading = true;
    this.getDahsboard();
  },

  methods: {
    getDahsboard() {
      this.isLoading = true;
      dashboardService
        .getTokenForExtendedDashboard(this.dashboardId)
        .then(res => {
          this.metabaseUrl = res.url;
          const refreshTime = fromUnixTime(res.exp);
          const milliseconds = differenceInMilliseconds(refreshTime, new Date());
          this.delayRefreshDashboard(milliseconds);
        })
        .catch(error => {
          console.error('error: ', error);
        });
    },

    delayRefreshDashboard(milliseconds) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      if (milliseconds > 0) {
        this.timeoutId = setTimeout(() => this.getDahsboard(), milliseconds);
      }
    },
  },
};
</script>

<style lang="scss">
.dasboard-iframe {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 96px);
}
.dashboard-extended-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
