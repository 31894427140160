<template>
  <div>
    <div class="page-title mb-5">{{ $t('navigation.dashboard') }}</div>

    <div v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-else-if="metabases.length">
      <v-tabs v-model="tab" class="mb-10">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="item in items" :key="item.id" :tab-value="item.value" link :to="item.link" :exact="exactRoute">
          {{ $t(item.label) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="$options.DASHBOARD_MAIN">
          <dashboard-portfolio />
          <dashboard-graph :mode="mode" />
        </v-tab-item>
        <v-tab-item :value="$options.DASHBOARD_EXTENDED">
          <v-card class="metabase-wrapper">
            <v-tabs show-arrows dark background-color="primary">
              <v-tab v-for="metabase in metabases" :key="metabase.id">
                <span>{{ metabase.name }}</span>
              </v-tab>

              <v-tab-item v-for="metabase in metabases" :key="metabase.id">
                <v-card flat>
                  <extended-item :dashboard-id="metabase.id" />
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div v-else>
      <dashboard-portfolio />
      <dashboard-graph :mode="mode" />
    </div>
  </div>
</template>

<script>
import DashboardPortfolio from '@/components/Dashboard/DashboardPortfolio.vue';
import DashboardGraph from '@/components/Dashboard/DashboardGraph.vue';
import ExtendedItem from '@/components/Dashboard/ExtendedItem.vue';

import { DASHBOARD } from '@/constants/analyticsActions';
import { DASHBOARD as DASHBOARD_MAIN, DASHBOARD_EXTENDED } from '@/constants/routes';
import analyticsService from '@/services/analytics';

import dashboardService from '@/services/dashboard';

export default {
  name: 'Dashboard',
  components: {
    DashboardPortfolio,
    DashboardGraph,
    ExtendedItem,
  },
  props: {
    mode: { type: String, required: true },
  },

  data() {
    return {
      isLoading: true,
      tab: null,
      items: [
        {
          id: 0,
          label: 'dashboard.main_dashboard',
          link: { name: DASHBOARD_MAIN },
          value: DASHBOARD_MAIN,
        },
        {
          id: 1,
          label: 'dashboard.extended_dashboards',
          link: { name: DASHBOARD_EXTENDED },
          value: DASHBOARD_EXTENDED,
        },
      ],
      options: {},
      metabases: [],
      metabaseUrl: null,
    };
  },

  computed: {
    dashboardMainValue() {
      return DASHBOARD_MAIN;
    },
    exactRoute() {
      return this.$route.name === DASHBOARD_EXTENDED;
    },
  },

  mounted() {
    analyticsService.track(DASHBOARD);

    dashboardService
      .getExtendedDashboard()
      .then(({ results }) => {
        this.metabases = results;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    async getMetabaseUrl(id) {
      const { url } = await dashboardService.getTokenForExtendedDashboard(id);
      return url;
    },
  },

  DASHBOARD_MAIN,
  DASHBOARD_EXTENDED,
};
</script>

<style lang="scss">
.metabase-wrapper {
  min-height: calc(100vh - 96px);
  margin: 4px;
}

.v-slide-group__prev.v-slide-group__prev--disabled,
.v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}
</style>
